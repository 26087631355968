import { debug } from './bannersDebug';

function BannersFilter(engine) {
  const { settings } = engine;
  const vLimits = engine.viewsLimits;
  const { pageViewsCount } = settings;

  function windowWidthGet() {
    return window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
  }

  function inArray(el, array) {
    return array.indexOf && (array.indexOf(el) !== -1);
  }

  function getTagsArray(tagsString) {
    const tagsSplit = tagsString.toLowerCase().split(',');
    const tags = [];
    for (let i = 0; i < tagsSplit.length; i += 1) {
      const tag = tagsSplit[i].trim();
      if (tag !== '') tags.push(tag);
    }
    return tags;
  }

  function uriMatched(uriTarget) {
    const path = window.location.pathname;
    const { uriList } = settings;
    if (uriTarget === '' || uriTarget === '*') return true;
    const patterns = uriTarget.split('\n');
    for (let n = 0; n < patterns.length; n += 1) {
      let pattern = patterns[n].trim();
      if (pattern.indexOf('tags:') === 0) {
        // tags (all tags must be in pageTags)
        const pageTags = typeof (settings.tags) === 'string' ? getTagsArray(settings.tags) : [];
        const tags = getTagsArray(pattern.substring(5));
        let matched = tags.length > 0;
        for (let i = 0; i < tags.length; i += 1) {
          if (!inArray(tags[i], pageTags)) {
            matched = false;
            break;
          }
        }
        if (matched) {
          return true;
        }
      } else {
        // URIs
        const pTmp = pattern.replace(/\*/g, '');
        if (Object.prototype.hasOwnProperty.call(uriList, pTmp)) {
          pattern = pattern.replace(pTmp, uriList[pTmp]);
        }
        if (pattern !== '') {
          if (pattern.substring(0, 1) !== '/') pattern = `/${pattern}`;

          const chunks = pattern.split('*');
          if (chunks.length === 1 && pattern === path) { // exact match
            return true;
          } if (chunks.length === 2 && chunks[1] === '' && path.indexOf(chunks[0]) === 0) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function geoMatched(originalgeoStr, originalCountry) {
    let country = originalCountry;
    let geoStr = originalgeoStr;
    const minus = geoStr.substr(0, 1) === '-';
    const ok = !minus;
    if (minus && geoStr === '-') {
      geoStr += '*';
    }
    let state = '';
    if (country.indexOf('-') !== -1) {
      [country, state] = country.split('-');
    }
    const ids = geoStr.replace(/[\s-]/g, '').split(',');
    // check US state separately
    if ((country === 'us' || country === 'ca' || country === 'in') && state) {
      // if us state, and no -us
      state = `${country}${state}`;
      // check state
      if (!inArray(state, ids) && !inArray(country, ids)) {
        return !ok;
      }
    } else if (!inArray(country, ids)) {
      return !ok;
    }
    return ok;
  }

  const { geoTarget } = settings;
  const casinos = settings.casinos || [];
  const sport = settings.sport || [];
  const roomsAllowed = settings.roomId || [];
  const { device } = settings;
  const testBannerId = settings.testBannerId ? settings.testBannerId : false;

  // filter banners
  const windowWidth = windowWidthGet();
  const hideAnimated = windowWidth < 1000;

  let { lastPopupTime } = settings;
  const now = Math.round((new Date()).getTime() / 1000);
  lastPopupTime = lastPopupTime ? parseInt(lastPopupTime, 10) : 0;
  const hidePopups = now - lastPopupTime < 86400 ? 86400 - (now - lastPopupTime) : 0;

  return function debugOutput(banner) {
    const bId = banner.id;

    // bId value is a string. Backend should provide integer type in JSON.
    // eslint-disable-next-line eqeqeq
    if (testBannerId && testBannerId != bId) {
      return false;
    }
    // *FILTERS*
    // geo target
    if (geoTarget && banner.geo_target && !geoMatched(banner.geo_target, geoTarget)) {
      debug(`skip-rule: geoTarget for ${bId}`);
      if (!testBannerId) return false;
    }
    // device target
    if (device && banner.device && !(banner.device && device)) {
      debug(`skip-rule: device for ${bId}`);
      if (!testBannerId) return false;
    }
    if (settings.onlyRoomID) {
      let onlyId = '';
      if (banner.room_id) {
        onlyId = `poker:${banner.room_id}`;
      } else if (banner.casino_room_id) {
        onlyId = `casino:${banner.casino_room_id}`;
      } else if (banner.sport_room_id) {
        onlyId = `sport:${banner.sport_room_id}`;
      }
      if (onlyId !== settings.onlyRoomID) {
        debug(`skip-rule: only room id ${settings.onlyRoomID} for ${bId}`);
        if (!testBannerId) return false;
      }
    }
    // casinos
    if (banner.casino_room_id && !inArray(parseInt(banner.casino_room_id, 10), casinos)) {
      debug(`skip-rule: casino room for ${bId}`);
      if (!testBannerId) return false;
    }
    // sport
    if (banner.sport_room_id && !inArray(parseInt(banner.sport_room_id, 10), sport)) {
      debug(`skip-rule: sport room for ${bId}`);
      if (!testBannerId) return false;
    }
    // room id
    if (banner.room_id && !inArray(parseInt(banner.room_id, 10), roomsAllowed)) {
      debug(`skip-rule: room for ${bId}`);
      if (!testBannerId) return false;
    }
    // uri exclude
    if (banner.uri_target && !uriMatched(banner.uri_target)) {
      debug(`skip-rule: uri_target for ${bId}`);
      if (!testBannerId) return false;
    }
    if (banner.uri_target_excl && uriMatched(banner.uri_target_excl)) {
      debug(`skip-rule: uri_target_excl for ${bId}`);
      if (!testBannerId) return false;
    }
    // emptybanner
    if (banner.isEmpty) {
      debug(`skip-rule: emptyBanner for ${bId}`);
      if (!testBannerId) return false;
    }
    if (banner.vLimit && !vLimits.canView(bId, banner.vLimit)) {
      debug(`skip-rule: vLimit for ${bId}(wait ${vLimits.canViewAfter} s)`);
      if (!testBannerId) return false;
    }

    if (banner.pageviews_to_skip && pageViewsCount < banner.pageviews_to_skip) {
      debug(`skip-rule: pageviews_to_skip for ${bId} (current pageview: ${1 + pageViewsCount})`);
      if (!testBannerId) return false;
    }
    if (hidePopups && banner.animation === 'popup') {
      debug(`skip-rule: only one popup per day for ${bId} (wait ${hidePopups} s)`);
      if (!testBannerId) return false;
    }
    // animated
    if (banner.animation === 'scrolled-in-article' && windowWidth > 480) {
      debug(`skip-rule: scrolled-in-article only when width<480px for ${bId}`);
      return false;
    } if (hideAnimated && banner.animation && ['slider+popup', 'sponsored:post', 'scrolled-in-article'].indexOf(banner.animation) === -1) {
      debug(windowWidth, '<1000');
      debug(`skip-rule: animation and window width for ${bId}`);
      return false;
    }

    return true;
  };
}

export default BannersFilter;
