function localStorageAvailable() {
  const mod = 'dummy_ls_test';
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}

const storageAvailable = localStorageAvailable();

function StorageWrapper() {
  this.storageGet = function storageGet(varName) {
    return storageAvailable
      ? localStorage.getItem(varName)
      : this.cookieGet(varName);
  };

  this.storageSet = function storageSet(varName, value) {
    return storageAvailable
      ? localStorage.setItem(varName, value)
      : this.cookieSet(varName, value, 1);
  };

  this.sessionGet = function sessionGet(varName) {
    return storageAvailable
      ? sessionStorage.getItem(varName)
      : this.cookieGet(varName);
  };

  this.sessionSet = function sessionSet(varName, value) {
    return storageAvailable
      ? sessionStorage.setItem(varName, value)
      : this.cookieSet(varName, value, 1);
  };

  this.cookieSet = function cookieSet(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toGMTString()}`;
    }
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
  };

  this.cookieGet = function cookieGet(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    const l = ca.length;
    for (let i = 0; i < l; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
  };
}

export default StorageWrapper;
