import { isDebugOn, debug } from './bannersDebug';
import BannersExclusiveCampaigns from './bannersExclusiveCampaigns';

let queuesList = {};
let bannersList;
let zoneBanners = {};
let ExclusiveCampaigns;

function shuffleArray(originalArray) {
  const array = originalArray;
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function distributeBannersPerZones(banners) {
  Object.keys(banners).forEach((bannerId) => {
    if (banners[bannerId]) {
      const zones = banners[bannerId].zones.split(',');
      zones.forEach((zone) => {
        if (typeof zoneBanners[zone] === 'undefined') {
          zoneBanners[zone] = [];
        }
        zoneBanners[zone].push(bannerId);
      });
    }
  });
}

function getQueueName(zone) {
  return zone;
}

function createQueue(queueName) {
  const queueItems = [];

  function queueItemCreate(id) {
    const banner = bannersList.itemGet(id);
    if (banner.slots && typeof (banner.slots[queueName]) !== 'undefined') {
      const weightInZone = parseInt(banner.slots[queueName], 10);
      queueItems.push({
        id,
        priority: typeof (banner.priority) !== 'undefined' ? parseInt(banner.priority, 10) : 99,
        weight_orig: weightInZone,
        weight: weightInZone * Math.random(),
      });
    }
  }

  function queueItemsCompare(b1, b2) {
    if (b1.priority === b2.priority) {
      return (b1.weight > b2.weight ? -1 : 1);
    }
    return b1.priority - b2.priority;
  }

  const bannerIds = typeof (zoneBanners[queueName]) === 'undefined'
    ? []
    : zoneBanners[queueName];
  bannerIds.forEach(queueItemCreate);
  queueItems.sort(queueItemsCompare);

  if (queueName === 'popup') {
    // PN-9639 find popup
    shuffleArray(queueItems);
  }

  const queue = [];
  queueItems.forEach((qItem) => {
    queue.push(qItem.id);
  });

  if (isDebugOn) {
    const debugInfo = [];
    queueItems.forEach((qb) => {
      debugInfo.push(`${qb.id}[p:${qb.priority},w:${Math.round(qb.weight * 100) / 100} (${qb.weight_orig})]`);
    });
    debug(`* ${queueName}* queue: \n${debugInfo.join(', ')}`);
  }

  queuesList[queueName] = queue;

  return queue;
}

class BannersQueue {
  constructor(engine) {
    bannersList = engine.banners;
    queuesList = {};
    zoneBanners = {};

    distributeBannersPerZones(bannersList.getList());
  }

  // eslint-disable-next-line class-methods-use-this
  detectExclusiveCampaign(slots) {
    let ids = [];
    slots.forEach((slot) => {
      const queueName = getQueueName(slot.zone);
      if (!queuesList[queueName]) {
        ids = ids.concat(createQueue(queueName));
      }
    });

    ExclusiveCampaigns = new BannersExclusiveCampaigns(ids, bannersList, debug);
  }

  // eslint-disable-next-line class-methods-use-this
  getSlotQueue(zone) {
    const queueName = getQueueName(zone);
    const initialQueue = queuesList[queueName] || createQueue(queueName);
    let queue = initialQueue.concat([]); // make a clone

    // PN-9116
    const hasExclusive = ExclusiveCampaigns && !ExclusiveCampaigns.empty();
    if (hasExclusive) {
      queue = ExclusiveCampaigns.filterSlotQueue(queue);
    } else {
      const notShownYet = queue.filter((id) => !bannersList.isShown(id));
      if (notShownYet.length) {
        queue = notShownYet;
      } else if (queue.length > 1) {
        // move the first to the end
        queuesList[queueName].push(queuesList[queueName].shift());
      }
    }
    debug(`${zone} | queue:`, queue);

    return queue;
  }
}

export default BannersQueue;
